<template>
  <div>
    <template v-if="typeof value==='object' && value!== null">
      <template v-if="!isset(value[0])">
        <!-- v-if="( Object.keys(value).length <2 || type=='HTTP' || type=='DIAMETER' )" -->
        <li class="directory expanded" style="cursor:pointer;">
          <div class="qahead" v-on:click="isHiddenTreeView = !isHiddenTreeView">
            <div class="treeIconContainer">
              <v-icon v-if="isHiddenTreeView===false" size="18">mdi-minus-box-outline</v-icon>
              <v-icon v-else size="18">mdi-plus-box-outline</v-icon>
            </div>
            <div class="textContainer">
              <span class="fWB fMS fLU">{{ attr }}:</span>
            </div>
          </div>

          <div v-if=" attr == 'content'" class="treeViewList"></div>
          <!-- v-if="atttrKey="header"" {boxclass="boxForPacketHeader" } {/if} -->
          <ul
              v-if="!isHiddenTreeView"
              v-bind:class="checkAttrForClass"
              class="jqueryFileTree unsetBullet"
          >
            <recursiveTreeBuilder
                :inputArray="value"
                :attrsNames="attrsNames"
                :type="type"
                :attrEnum="attrEnum"
                :localSearch="localSearch"
            ></recursiveTreeBuilder>
          </ul>
        </li>
        <!-- <template v-else>
                      {{value }}
                    <treeBuilderValue
                            v-for="val in value"
                            :value="val"
                            :key="val"
                            :attrEnum="attrEnum"
                    ></treeBuilderValue>
        </template>-->
      </template>
      <template v-else>
        <treeBuilderValue
            v-for="(val, index)  in value"
            :value="val"
            :key="index"
            :attrEnum="attrEnum"
            :attrsNames="attrsNames"
            :atttrKey="atttrKey"
            :localSearch="localSearch"
            :type="type"
        ></treeBuilderValue>
      </template>
    </template>
    <template v-else>
      <div v-if="attr == 'ORIGINAL'" class="original" style="display: none;">
        <textarea
            class="originalXmlContent boxForPacketHeader autoGrow"
            style="width:500px"
            readonly="readonly"
            :value="shownValue"
        ></textarea>
      </div>
      <template v-else>
        <li v-if="(atttrKey != 'dataType' && !conditionedAttr) && searchList" class="file " style="cursor:pointer;">
          <div v-if="attr!='data'" class="treeIconContainer">
            <v-icon>mdi-square-medium</v-icon>
            <i class="fas fa-square squareIconLi"></i>
          </div>
          <div class="textContainer" style="word-wrap: break-word;">
            <span v-if=" attr == 'HTTP'" class="fWB fMS fLU">{{ attr }} {{ shownValue }}</span>
            <span v-else-if="attr=='data'">
            <div class="qahead" v-on:click="isHiddenTreeView = !isHiddenTreeView">
            <div class="treeIconContainer">
              <v-icon v-if="isHiddenTreeView===false" size="18">mdi-minus-box-outline</v-icon>
              <v-icon v-else size="18">mdi-plus-box-outline</v-icon>
            </div>
            <div class="textContainer">
               <span class="fWB fMS fLU">{{ attr }}:</span>
            </div>
          </div>
          </span>
            <span v-else class="fWB fMS fLU">
               {{ attr }}:</span>
          </div>
          <div v-if="checkXml(shownValue) && !isHiddenTreeView" class="translated">
            <textarea
                class="xmlContent boxForPacketHeader autoGrow"
                readonly="readonly"
                :value="shownValue"
            ></textarea>
          </div>
          <template v-else>
            <pre v-if="type=='SSH'" class="boxForPacketHeader"
                 style="padding-left:7px;white-space: pre-wrap;"> {{ shownValue }} </pre>
            <template v-else-if="type=='HTTP' && attr=='content'">
              <br/>
              <!-- {shownValue=str_replace ('\n','',shownValue)}
              {shownValue=str_replace ('\r','',shownValue)}-->
              <textarea readonly="readonly" class="boxForPacketHeader autoGrow" :value="shownValue"></textarea>
            </template>
            <template v-else>
              <template v-if="atttrKey == 'data'">
                <!--
                <div v-if="shownValue == strip_tags(shownValue)" class="boxForPacketHeader fMS">
                -->
                <div v-if="shownValue == isHTML(shownValue)" class="boxForPacketHeader fMS">
                  <xmp class="contentNewLine">{{ shownValue }}</xmp>
                </div>

                <template v-else>
                  <!-- {shownValue=str_replace ('\n','&#10;',shownValue)} -->
                  <!-- {shownValue=str_replace ('\r','&#13;',shownValue)} -->
                  <div v-if="!isHiddenTreeView" class="boxForPacketHeader fMS">
                    <div class="httpTextResponseContainer">{{ shownValue }}</div>
                  </div>
                </template>
              </template>
              <template v-else-if="atttrKey == 'dataType'"></template>
              <template v-else>
                <template v-if="attr !== 'HTTP' && !conditionedAttr">
                  <template v-if="attr==='Command'">
                    <div
                        v-bind:class="checkAttrForClass"
                        class="fMS ml-1"
                        style="white-space:normal;"
                    >{{ shownValue }}
                    </div>
                  </template>
                  <template v-else>
                    <span
                        v-bind:class="checkAttrForClass"
                        class="fMS ml-1"
                        style="white-space:normal;"
                    >{{ shownValue }}</span>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </li>
      </template>
    </template>
  </div>
</template>
<script>
import {checkXml, isHTML, convertMs} from "@/js/helper.js";
import options from "@/cfg/options.json";

export default {
  name: "treeBuilderValue",
  data: function () {
    return {
      isHiddenTreeView: false,
    };
  },
  created() {
    //console.log(typeof this.value);
    // if (
    //   Object.keys(this.value).length < 2 ||
    //   this.type == "HTTP" ||
    //   this.type == "DIAMETER"
    // ) {
    //   this.isHiddenTreeView = false;
    // }
  },
  components: {
    recursiveTreeBuilder: () => import("@/components/legacy/recursiveTreeBuilder.vue"),
  },
  props: [
    "atttrKey",
    "value",
    "attrsNames",
    "inputArray",
    "type",
    "attrEnum",
    "localSearch",
  ],
  computed: {
    conditionedAttr(){
      let hideAttributes = [];
      if(this.type == "RADIUS"){
        hideAttributes = ["NativeValue"];
      }else if(this.type=='Explorer_cellular_data_connect'){
        hideAttributes = ["subscriber","msisdn"];
      }
      return hideAttributes.includes(this.attr);
    },
    searchList() {
      //console.log("shownValue:", typeof(this.shownValue));
      //console.log("localSearch:", this.localSearch);
      //console.log("Attr:", this.attr);
      let query = this.localSearch;
      if (query == "" ||
          (typeof this.attr != "undefined" && this.attr.toLowerCase().indexOf(query.toLowerCase()) > -1) ||
          (typeof this.shownValue != "undefined" && typeof (this.shownValue) != "number" && this.shownValue.toLowerCase().indexOf(query.toLowerCase()) > -1)
      ) {
        // var iQuery = new RegExp(query, "ig");
        //console.log('iQuery:',iQuery);
        /*this.attr.toString().replace(iQuery, function(matchedTxt){
              return ('<span class=\'highlight\'>' + matchedTxt + '</span>');
             });*/
        return true;
      } else {
        return false;
      }
    },
    checkAttrForClass() {
      return this.atttrKey == "header" || this.attr == "Command"
          ? "boxForPacketHeader"
          : "";
    },
    shownValue() {
      let shownValue = this.value;
      //console.log("VALUE--->",shownValue);
      //console.log("key--->",this.atttrKey);
      if (this.atttrKey === 'password') {
        shownValue = "*******";
      }
      if (this.type == "LDAP" && this.atttrKey == 'operation' && this.isset(options.operationLADP[this.value])) {
        shownValue = options.operationLADP[this.value][1];
      } else if (this.type == "LDAP" && this.atttrKey == 'resultCode' && this.isset(options.resultCodeLDAP[this.value])) {
        shownValue = options.resultCodeLDAP[this.value][1];
      } else if (this.type == "LDAP" && this.atttrKey == 'Entry: responseType' && this.isset(options.responseLDAP[this.value])) {
        shownValue = options.responseLDAP[this.value][1];
      } else if (this.type == "RADIUS" && this.attr == 'code') {
        let result = options.radiusCode.find(a => a[0] == this.value);
        shownValue = result[1];
      } else if (this.type == "Explorer_SIP_CALL") {
        shownValue = this.value;
        let result = this.attrEnum.find(a => a[0] == this.atttrKey);
        if (typeof result !== "undefined" && typeof result[1] !== "undefined") {
          if (result[1] === "ns") {
            shownValue = this.convertMs(this.value);
          } else if (result[1] === "percent") {
            shownValue = this.value + " " + "%";
          } else if (result[1] === "ms") {
            shownValue = this.value + " " + "ms";
          }
        }

      }else if(this.type=="Explorer_TWAMP"){
        //console.log(this.atttrKey)
        shownValue = this.value;
         if(this.atttrKey=='lost'){
           shownValue = this.value+ " %";
         }else if(this.atttrKey!='successful' && this.atttrKey!='errorCode' && this.atttrKey!='message' ){
           shownValue = this.convertMs(this.value);
         }
      }
      if (this.isset(this.attrEnum[this.atttrKey]) &&
          this.isset(this.attrEnum[this.atttrKey][this.value])
      ) {
        shownValue = this.attrEnum[this.atttrKey][this.value];
      }

      if (this.checkXml(shownValue)) {
        let cleanText = shownValue.replace(/(\r\n|\n|\r|\\n)/gm, "");//sometimes xml coming with \n
        let format = require('xml-formatter');
        shownValue = format(cleanText);
      }
      return shownValue;
    },

    attr() {
      let attr = this.atttrKey;
      if (this.type == "RADIUS") {
        let attrArray = this.attrsNames.find(a => a[0] == this.atttrKey);
        if (typeof attrArray !== 'undefined') {
          attr = attrArray[1];
        }
      } else if (typeof this.attrsNames[attr] != "undefined") {
        attr = this.attrsNames[attr];
      } else if (this.type == "Explorer_SIP_CALL") {
        let result = this.attrsNames.find(a => a[0] == this.atttrKey);
        if (typeof result !== "undefined" && typeof result[1] !== "undefined") {
          attr = result[1];
        }
      }
      //console.log(this.attrsNames);
      //console.log(attr);
      return attr;
    },
  },

  methods: {
    checkXml: checkXml,
    isHTML: isHTML,
    convertMs: convertMs
  },
};
</script>
<style>
.treeIconContainer {
  display: inline-block;
  width: 20px;
}

.textContainer {
  display: inline-block;
}

.fMS {
  font-family: monospace;
  font-size: 1.1em;
}

.fWB {
  font-weight: bold;
}

.tree {
  list-style: outside none none;
}

.fLU {
  text-transform: capitalize;
}

.unsetBullet {
  list-style: unset;
}

.boxForPacketHeader {
  background: #dbedff;
  min-width: 400px;
  width: 95%;
  border-radius: 3px;
  border: 1px solid #5c9ccc;
  position: relative;
  left: 18px;
  resize: none;
  cursor: pointer;
  font-size: 10px;
  font-family: monospace;
  min-height: 55px;
  margin-right: 15px;
}

.contentNewLine {
  white-space: pre-wrap;
  padding: 5px;
}

.httpTextResponseContainer {
  white-space: pre-line;
  padding: 3px;
  line-height: normal;
}

pre.boxForPacketHeader {
  font-size: unset;
}

.xmlContent {
  height: 200px !important;
  line-height: 18px;
}
</style>