<template>
  <div v-if="isset(inputArray) && typeof inputArray == 'object'">
    <!-- {{ sortedInputArray }} -->
    <div v-for="info in sortedInputArray" :key="info[0]">
      <template
        v-if="
          info[0] == 'data' &&
          isset(inputArray.dataType) &&
          inputArray.dataType == 'JSON'
        "
      >
        <!-- {$id=rand ( 0 , 10000 )}
      <script id="script{$id}">
  var jsonContent = {{value|@json_encode}};
  $(function() {
    $(".jsonView_{$id}").JSONView(jsonContent);
   });
        </script>-->

        <li
          class="directory expanded"
          style="cursor: pointer; padding-top: 3px"
        >
          <div class="qahead" v-on:click="isHiddenTreeView = !isHiddenTreeView">
            <div class="treeIconContainer">
              <v-icon v-if="isHiddenTreeView === false" size="18"
                >mdi-minus-box-outline</v-icon
              >
              <v-icon v-else size="18">mdi-plus-box-outline</v-icon>
            </div>
            <div class="textContainer">
              <span class="fWB fMS"
                >{{ language.componentLabel.labelData }}:</span
              >
            </div>
          </div>
          <ul v-if="!isHiddenTreeView" class="boxForPacketHeader packetDataBox">
            <json-viewer
              :expand-depth="1000"
              :value="jsonViewerValue(info[1])"
              copyable
            ></json-viewer>
          </ul>
        </li>
      </template>
      <treeBuilderValue
        v-else
        :atttrKey="info[0]"
        :value="info[1]"
        :inputArray="inputArray"
        :type="type"
        :attrsNames="attrsNames"
        :attrEnum="attrEnum"
        :localSearch="localSearch"
      ></treeBuilderValue>
    </div>
  </div>
</template>
<script>
import JsonViewer from "vue-json-viewer";
import treeBuilderValue from "@/components/legacy/treeBuilderValue.vue";
//import treeBuilderValue from "@/components/treeBuilderValue.vue";
import { filterObjectArray } from "@/js/helper.js";
export default {
  name: "recursiveTreeBuilder",
  props: ["inputArray", "attrsNames", "type", "attrEnum", "localSearch"],
  components: {
    JsonViewer,
    treeBuilderValue,
  },
  data: function () {
    return {
      isHiddenTreeView: false,
    };
  },
  computed: {
    sortedInputArray() {
      let sortedInputArray = [];
      //let attrsNames = this.attrsNames;
      if (this.inputArray != null) {
        let inputArrayKey = Object.keys(this.inputArray);

        /*   inputArrayKey.sort(function (x, y) {
          let xname = x;
          if (typeof attrsNames[x] != "undefined") {
            xname = attrsNames[x];
          }
          let yname = y;
          if (typeof attrsNames[y] != "undefined") {
            yname = attrsNames[y];
          }
          return xname.localeCompare(yname);
        });*/
        //console.log("---->",inputArrayKey);
        inputArrayKey.forEach((key) => {
          sortedInputArray.push([key, this.inputArray[key]]);
        });
      }
      return sortedInputArray;
    },
  },
  methods: {

    jsonViewerValue(sentValue) {
      if (typeof sentValue == "string") {
        sentValue = JSON.parse(sentValue);
      }
      return filterObjectArray(sentValue, this.localSearch);
    },
  },
};
</script>
<style scoped>
.jv-container.jv-light {
  background: unset;
}
</style>